import React, { useState, useEffect, useRef } from 'react';
import {
  DraggableItem,
  Position,
} from '../PracticeSessionThree/interfaces/DraggableInterface';
import { IFlashCard } from '../../Utils/constants/interfaces';
import PressGif from '../../Assets/Gifs/Press Animation.gif';
import { Typography, Stack, Box } from '@mui/material';
import { RECORDER_STATES } from '../../Utils/constants/speechSession';
import styles from './styles.module.scss';

interface DraggableFlashcardProps {
  flashCard: IFlashCard;
  flashcardsTop: DraggableItem[];
  flashcardsBottom: DraggableItem[];
  setAnswerSound: (sound: string) => void;
  setIsAnswerSoundPlaying: (playing: boolean) => void;
  setRecorderStatus: (state: string) => void;
  cardOpacity: string;
  checkResult: boolean;
  setCheckResult: (result: boolean) => void;
}

const DraggableFlashcards: React.FC<DraggableFlashcardProps> = ({
  flashCard,
  flashcardsTop,
  flashcardsBottom,
  setAnswerSound,
  setIsAnswerSoundPlaying,
  setRecorderStatus,
  cardOpacity,
  checkResult,
  setCheckResult,
}) => {
  const [draggedItem, setDraggedItem] = useState<DraggableItem>();

  const ghostRef = useRef<HTMLDivElement | null>(null);
  const CORRECT_ANSWER_SOUND = '/SampleSounds/correct-answer.wav';

  const [correctIndexTop, setCorrectIndexTop] = useState(-1);
  const [correctIndexBottom, setCorrectIndexBottom] = useState(-1);
  const [incorrectIndexTop, setIncorrectIndexTop] = useState(-1);
  const [incorrectIndexBottom, setIncorrectIndexBottom] = useState(-1);
  const correctTop = useRef<HTMLDivElement>(null);
  const correctBottom = useRef<HTMLDivElement>(null);
  const [showPointer, setShowPointer] = useState<boolean>(false);
  const [positionPointer, setPositionPointer] = useState({ x: 0, y: 0 });

  const onDragStart = (event: React.DragEvent) => {
    const targetElement = event.target as HTMLElement;
    const { id, innerText } = targetElement;
    setDraggedItem({ id, flashcard_value: innerText });
  };

  const onDragOver = (event: React.DragEvent) => {
    event.preventDefault();
  };

  const onDrop = (event: React.DragEvent) => {
    event.preventDefault();
    const targetElement = event.target as HTMLElement;
    const { id, innerText } = targetElement;
    if (targetElement && id.toUpperCase() in Position) {
      handleDrop(draggedItem!, { id, flashcard_value: innerText });
    }
    setDraggedItem(undefined);
  };

  const onTouchStart = (event: React.TouchEvent) => {
    event.preventDefault();
    const targetElement = event.target as HTMLElement;
    const { id, innerText } = targetElement;
    setDraggedItem({ id, flashcard_value: innerText });
    createDragImage(event, innerText);
  };

  const onTouchEnd = (event: React.TouchEvent) => {
    removeDragImage();
    const touch = event.changedTouches[0];
    const targetElement = document.elementFromPoint(
      touch.clientX,
      touch.clientY
    ) as HTMLElement;
    const { id, innerText } = targetElement;
    if (targetElement && id.toUpperCase() in Position) {
      handleDrop(draggedItem!, { id, flashcard_value: innerText });
    }
    setDraggedItem(undefined);
  };

  // Handle touch move (for touch)
  const onTouchMove = (event: React.TouchEvent) => {
    event.preventDefault(); // Prevent default touch action (scrolling)
    if (!draggedItem) return;
    moveDragImage(event);
  };

  // Create a ghost image for touch drag
  const createDragImage = (
    event: React.DragEvent | React.TouchEvent,
    item: string
  ) => {
    const dragImage = document.createElement('div');

    dragImage.className = `${styles.box} ${styles.ghost}`;
    dragImage.textContent = item;
    dragImage.style.cssText += `textContent = ${item}; position: absolute; pointer-events: none; z-index: 2000; width: 80px; height: 100px; border-radius: 24px; background: white; border:solid cfd1d0;`;

    document.body.appendChild(dragImage);
    ghostRef.current = dragImage;
    moveDragImage(event);
  };

  // Move the ghost image for touch drag
  const moveDragImage = (event: any) => {
    const dragImage = ghostRef.current;
    if (dragImage) {
      dragImage.style.left = `${
        event.touches[0].clientX - dragImage.offsetWidth / 2
      }px`;
      dragImage.style.top = `${
        event.touches[0].clientY - dragImage.offsetHeight / 2
      }px`;
    }
  };

  const removeDragImage = () => {
    try {
      const dragImage = ghostRef.current;
      if (dragImage && document.body.contains(dragImage)) {
        document.body.removeChild(dragImage);
        ghostRef.current = null;
      }
    } catch (error) {
      console.error('Error while fetching ref of card', error);
    }
  };

  const resetStates = () => {
    setCheckResult(false);
    setIncorrectIndexBottom(-1);
    setIncorrectIndexTop(-1);
    setCorrectIndexTop(-1);
    setCorrectIndexBottom(-1);
    removeDragImage();
  };

  const handleDrop = (src: DraggableItem, dest: DraggableItem) => {
    if (checkResult) return;

    setShowPointer(false);
    setCheckResult(true);

    if (src.id === dest.id) {
      setCorrectIndexTop(-1);
      setCorrectIndexBottom(-1);
    } else if (
      src.id !== dest.id &&
      src.flashcard_value === dest.flashcard_value &&
      src.flashcard_value === flashCard.flashcard_value
    ) {
      // Play correct answer sound
      setAnswerSound(CORRECT_ANSWER_SOUND);
      setIsAnswerSoundPlaying(true);
      setRecorderStatus(RECORDER_STATES.correctAnswer);
    } else {
      const top = src.id === Position.TOP ? src : dest;
      const bottom = src.id === Position.BOTTOM ? src : dest;

      if (src.id === Position.TOP) setCorrectIndexTop(-1);
      else setCorrectIndexBottom(-1);

      flashcardsTop.map((flashcard, index) => {
        if (flashcard.flashcard_value === top.flashcard_value)
          setIncorrectIndexTop(index);
      });

      flashcardsBottom.map((flashcard, index) => {
        if (flashcard.flashcard_value === bottom.flashcard_value)
          setIncorrectIndexBottom(index);
      });
      // Play wrong answer sound
      setAnswerSound('');
      setIsAnswerSoundPlaying(true);
      setRecorderStatus(RECORDER_STATES.wrongAnswer);
    }
    setTimeout(() => resetStates(), 3000);
  };

  useEffect(() => {
    resetStates();
    flashcardsTop.map((word, index) => {
      if (word.id === flashCard.flashcard_uuid) setCorrectIndexTop(index);
    });

    flashcardsBottom.map((word, index) => {
      if (word.id === flashCard.flashcard_uuid) setCorrectIndexBottom(index);
    });
  }, [flashCard, flashcardsTop, flashcardsBottom]);

  useEffect(() => {
    const setPosition = () => {
      if (showPointer && correctTop.current && correctBottom.current) {
        const rect = correctTop.current.getBoundingClientRect();
        setPositionPointer({ x: rect.x, y: rect.y });
      }
    };

    const changePosition = () => {
      if (showPointer && correctTop.current && correctBottom.current) {
        const rect = correctBottom.current.getBoundingClientRect();
        setPositionPointer({ x: rect.x, y: rect.y });
      }
    };

    setPosition();
    const timer = setTimeout(changePosition, 2000);
    return () => clearTimeout(timer);
  }, [showPointer]);

  useEffect(() => {
    let timer = setTimeout(() => {
      setShowPointer(true);
    }, 2000);
    timer = setTimeout(() => {
      setShowPointer(false);
    }, 8000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    return () => {
      removeDragImage();
    };
  }, []);

  return (
    <>
      <Stack className={styles['title-words']}>
        <Typography className={styles['heading']}>
          Click or tap, drag and drop to match the correct words
        </Typography>
        <Stack
          direction="row"
          className={`${styles['separated']} ${styles[cardOpacity]}`}
        >
          {flashcardsTop.map((item, index) => (
            <Box
              id={Position.TOP}
              key={item.flashcard_value}
              ref={index === correctIndexTop ? correctTop : null}
              className={styles.bux}
              draggable
              onDragStart={(event) => onDragStart(event)}
              onDragOver={onDragOver}
              onDrop={(event) => onDrop(event)}
              onTouchStart={(event) => onTouchStart(event)}
              onTouchMove={onTouchMove}
              onTouchEnd={(event) => onTouchEnd(event)}
            >
              <Typography
                id={Position.TOP}
                variant="h1"
                className={`${styles['box']} ${
                  checkResult && index === correctIndexTop
                    ? `${styles['green']} ${styles['shake']}`
                    : index === incorrectIndexTop
                    ? styles['pink']
                    : styles['white']
                }`}
              >
                {item.flashcard_value}
              </Typography>
            </Box>
          ))}
        </Stack>
        <Stack
          direction="row"
          className={`${styles['separated']} ${styles[cardOpacity]}`}
        >
          {flashcardsBottom.map((item, index) => (
            <Box
              id={Position.BOTTOM}
              key={item.flashcard_value}
              ref={index === correctIndexBottom ? correctBottom : null}
              className={styles.bux}
              draggable
              onDragStart={(event) => onDragStart(event)}
              onDragOver={onDragOver}
              onDrop={(event) => onDrop(event)}
              onTouchStart={(event) => onTouchStart(event)}
              onTouchMove={onTouchMove}
              onTouchEnd={(event) => onTouchEnd(event)}
            >
              <Typography
                id={Position.BOTTOM}
                variant="h1"
                className={`${styles['box']} ${
                  checkResult && index === correctIndexBottom
                    ? `${styles['green']} ${styles['shake']}`
                    : index === incorrectIndexBottom
                    ? styles['pink']
                    : styles['white']
                }`}
              >
                {item.flashcard_value}
              </Typography>
            </Box>
          ))}
        </Stack>
        {positionPointer.x > 0 && showPointer && (
          <img
            src={PressGif}
            alt="Press Gif"
            className={styles['gif-overlay']}
            style={{
              transform: `translate(${positionPointer.x}px, ${positionPointer.y}px)`,
            }}
          />
        )}
      </Stack>
    </>
  );
};

export default DraggableFlashcards;
