import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { IFlashCard } from '../constants/interfaces';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

const handleError = (error: AxiosError) => {
  // if user is logged in
  // and current location is not login page, since it will be stuck in loop
  // and network error or token expires
  // then clear local storage and move to login again
  if (
    localStorage.user &&
    window.location.pathname !== 'login' &&
    (error.message === 'Network Error' || error.response?.status === 401)
  ) {
    localStorage.clear();
    window.location.href = '/';
  }
  throw error;
};

api.interceptors.response.use((response) => response, handleError);

const login = async (email: string, password: string) => {
  const response = await api.post('/auth/login', {
    email,
    password,
  });
  return response.data;
};

const getSubscriptionPlans = async (type: string, token: string) => {
  const response = await api.get(`/stripe/products/${type}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

const updatePaymentMethod = async (
  customer_id: string,
  payment_method_id: string,
  token: string
) => {
  const response = await api.put(
    `/stripe/update-payment-method`,
    {
      customer_id,
      payment_method_id,
    },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data;
};

const getCoupon = async (couponCode: string, token: string) => {
  const params: Record<string, any> = {};
  params.coupon = couponCode;
  const response = await api.get(`/stripe/check-coupon`, {
    params,
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

const createSubscription = async (
  customer_id: string,
  payment_id: string,
  plan_id: string,
  couponCode: string,
  marketingEnabled: boolean,
  token: string
) => {
  const response = await api.post(
    '/stripe/create-subscriptions',
    {
      customer_id,
      plan_id,
      payment_id,
      couponCode,
      marketingEnabled,
    },
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return response.data;
};

const upgradeSubscription = async (
  customer_id: string,
  payment_id: string,
  plan_id: string,
  subscriptionId: string,
  token: string
) => {
  const response = await api.post(
    `/stripe/upgrade-plan/${subscriptionId}`,
    {
      customer_id,
      plan_id,
      payment_id,
    },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data;
};

const deactivateAccount = async (token: string) => {
  const response = await api.put('/user/deactivate', null, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response;
};

const deleteAccount = async (
  token: string,
  user_id: string,
  password: string
) => {
  const response = await api.delete(`/user/hard/${user_id}`, {
    headers: { Authorization: `Bearer ${token}` },
    data: {
      password,
    },
  });
  return response;
};

const signup = async (formData: FormData): Promise<any> => {
  const config: AxiosRequestConfig = {
    method: 'post',
    maxBodyLength: Infinity,
    url: '/auth/signup',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
  };

  const response = await api.request(config);
  return response;
};

const updateUser = async (token: string, formData: FormData) => {
  const config: AxiosRequestConfig = {
    method: 'put',
    maxBodyLength: Infinity,
    url: '/user',
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
    data: formData,
  };

  const response = await api.request(config);
  return response;
};

const sendEmailVerificationLink = async (email: string) => {
  const response = await api.post('/auth/send-email-verfication', {
    email,
  });
  return response;
};

const verifyEmail = async (token: string) => {
  const response = await api.post('/auth/verify-email', {
    token,
  });
  return response;
};

const forgetPassword = async (email: string) => {
  const response = await api.post('/auth/forget-password', {
    email,
  });
  return response;
};

const resetPassword = async (token: string, newPassword: string) => {
  const response = await api.post('/auth/reset-password', {
    token,
    newPassword,
  });
  return response;
};

const changePassword = async (
  token: string,
  oldPassword: string,
  newPassword: string
) => {
  const response = await api.post(
    '/auth/change-password',
    {
      oldPassword,
      newPassword,
    },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response;
};

const addStudent = async (
  token: string,
  avatar_id: string,
  profile_name: string,
  age: number
) => {
  const response = await api.post(
    '/profile',
    {
      avatar_uuid: avatar_id,
      profile_name,
      age,
    },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response;
};

const editStudent = async (
  token: string,
  profile_id: string,
  avatar_id: string,
  profile_name: string,
  age: number
) => {
  const response = await api.put(
    `/profile/${profile_id}`,
    {
      avatar_uuid: avatar_id,
      profile_name,
      age,
    },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response;
};

const deleteStudent = async (token: string, profile_id: string) => {
  const response = await api.delete(`/profile/${profile_id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response;
};

const getFlashCards = async (
  token: string,
  userID: string,
  profileID: string
) => {
  const params: Record<string, any> = {};
  params.user_uuid = userID;
  params.profile_uuid = profileID;

  const response = await api.get('/flashcard-piles', {
    headers: { Authorization: `Bearer ${token}` },
    params,
  });
  return response.data;
};

const getUser = async (token: string) => {
  const response = await api.get(`/user`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

const getProfiles = async (token: string) => {
  const response = await api.get('/profile', {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

const getExchangeRate = async (
  price_id: string,
  country: string,
  token: string
) => {
  const response = await api.get(
    `/stripe/convert-price/${price_id}/${country}`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data;
};

const getUserSubscription = async (token: string) => {
  const response = await api.get('/stripe/subscriptions', {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

const getUserPaymentPlan = async (token: string) => {
  const response = await api.get('/stripe/payment-method', {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

const verifyRecording = async (
  profile_id: string,
  flashCard: IFlashCard,
  audioData: Blob,
  session_id: string,
  is_retry: boolean,
  token: string
) => {
  const flashCardObj = {
    flashcard_uuid: flashCard.flashcard_uuid,
    flashcard: flashCard.flashcard_value,
    category: flashCard.category_id,
    arpabet_sequence: flashCard.arpabet_sequence,
  };
  const formData = new FormData();
  formData.append('flashcards', JSON.stringify([flashCardObj]));
  formData.append('profile_uuid', profile_id);
  formData.append('file', audioData, 'audio_file.wav');
  formData.append('session_uuid', session_id);
  formData.append('session_type', 'Active');
  formData.append('is_retry', is_retry.toString());
  const response = await api.post('/soapbox/verify-sound', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

const getSessionByID = async (sessionID: number, token: string) => {
  const response = await api.get(`/session/${sessionID}`, {
    headers: { Authorization: `Bearer ${token}` },
  });

  return response.data;
};

const updateSessionByID = async (
  session_id: string,
  session_type: string,
  profile_id: string,
  token: string
) => {
  const response = await api.put(
    `/session/${session_id}`,
    {
      session_type,
      profile_uuid: profile_id,
      end_time: new Date(),
    },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data;
};

const createSession = async (
  session_type: string,
  profile_id: string,
  token: string
) => {
  const response = await api.post(
    '/session/create',
    {
      profile_uuid: profile_id,
      session_type,
      start_time: new Date(),
    },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data;
};

const getAllSessions = async (
  profile_id: string,
  token: string,
  page: number,
  limit?: number
) => {
  const params: Record<string, any> = {};
  params.profile_uuid = profile_id;
  params.page = page;

  if (limit) {
    params.limit = limit;
  }

  const response = await api.get('session/all', {
    headers: { Authorization: `Bearer ${token}` },
    params,
  });
  return response.data;
};

const getSessionResult = async (
  profile_id: string,
  session_id: number,
  token: string,
  page: number
) => {
  const params: Record<string, any> = {};
  params.page = page;
  params.limit = 5;
  const response = await api.get(`/user-result/${profile_id}/${session_id}`, {
    headers: { Authorization: `Bearer ${token}` },
    params,
  });
  return response.data;
};

const upgradeProfile = async (
  profile_id: string,
  current_level: number,
  token: string
) => {
  const response = await api.put(
    `/profile/upgrade_level/${profile_id}/${current_level}`,
    {},
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data;
};

const getProfileAvatars = async () => {
  const response = await api.get('/avatar');
  return response.data;
};

const getFlashCardsForPractice = async (
  token: string,
  user_id: string,
  profile_id: string,
  session_id: string
) => {
  const params: Record<string, any> = {};
  params.user_uuid = user_id;
  params.profile_uuid = profile_id;
  if (session_id) params.session_uuid = session_id;

  const response = await api.get('/flashcard-piles/practice', {
    headers: { Authorization: `Bearer ${token}` },
    params,
  });
  return response.data;
};

const serverApi = {
  signup,
  sendEmailVerificationLink,
  verifyEmail,
  login,
  deactivateAccount,
  deleteAccount,
  forgetPassword,
  resetPassword,
  changePassword,
  addStudent,
  editStudent,
  deleteStudent,
  getFlashCards,
  getProfiles,
  getSubscriptionPlans,
  getCoupon,
  createSubscription,
  getExchangeRate,
  getUserSubscription,
  getUserPaymentPlan,
  updatePaymentMethod,
  verifyRecording,
  updateSessionByID,
  getSessionByID,
  createSession,
  getAllSessions,
  getSessionResult,
  upgradeSubscription,
  getUser,
  updateUser,
  upgradeProfile,
  getProfileAvatars,
  getFlashCardsForPractice,
};

export default serverApi;
