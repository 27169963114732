import { Link, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import serverApi from '../../Utils/api';
import styles from './styles.module.scss';
import useAuth from '../../Context/Auth';
import { AxiosError } from 'axios';

const VerifyEmailBox = () => {
  const [status, setStatus] = useState<string>('');
  const { user } = useAuth();

  const handleResendEmail = async () => {
    try {
      const response = await serverApi.sendEmailVerificationLink(user.email);
      if (response.status === 201) {
        setStatus(response.data.message);
      } else {
        setStatus(response.data.message);
      }
    } catch (error) {
      console.error('Error while resending verification email', error);
      if (error instanceof AxiosError) setStatus(error.response?.data.message);
    }
  };

  return (
    <Stack className={styles['verify-email-box']}>
      <Stack width="90%" alignItems="center" spacing={5}>
        <Typography variant="h3" className={styles['header-text']}>
          Verify your Email
        </Typography>
        <Typography variant="h5" className={styles['sub-text']}>
          We have sent a verification email to {user.email}. Kindly check your
          Spam folder as well for email.
          <br />
          <br />
          Please click on it to continue.
        </Typography>

        <Stack direction="row" alignItems="center">
          <Typography variant="body1" className={styles['resend-text']}>
            Didn&apos;t receive? &nbsp;
          </Typography>
          <Link
            onClick={() => handleResendEmail()}
            underline="always"
            className={styles['resend-link']}
          >
            Resend Email
          </Link>
        </Stack>

        {status ? (
          <Typography variant="body1" className={styles['status-text']}>
            {status}
          </Typography>
        ) : null}
      </Stack>
    </Stack>
  );
};

export default VerifyEmailBox;
